import { Helmet } from "react-helmet"
import Layout from '../../components/Layout/Layout'
import React, { useState, SyntheticEvent } from "react"
import Alert from "../../components/Alert/Alert"


const IndexPage = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [showAlert, setShowAlert] = useState(false)
    const sendEmailFailedMsg = 'Request failed to send. We have logged the error. Please try again later if you have not heard from us in 24 hours.';

    const handleSubmit = (e: SyntheticEvent<HTMLElement>) => {
        e.preventDefault()
        window.grecaptcha.ready(async function () { 
            const token = await window.grecaptcha.execute('6LfBup8eAAAAAFf4MYxd86xLlOMdbDL3h2kGZXQW', { action: 'submit' });
            const body = {
                from: {
                    name: name,
                    email: email,
                },
                message: message,
                token: token
            };
            try {
                const request = await fetch("https://integrativewellness-contact.matt7067.workers.dev", {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                if (request.status === 202) {
                    setShowAlert(true)
                    setSuccess(true)
                    setError(false)
                    setErrorMessage('')
                } else {
                    const text = await request.text();
                    setShowAlert(true)
                    setSuccess(false)
                    setError(true)
                    setErrorMessage(text)
                }
            } catch (error) {
                setShowAlert(true)
                setSuccess(false)
                setError(true)
                setErrorMessage(sendEmailFailedMsg)
            }
        })
    }



    return (
        <Layout>
            <Helmet title="Contact" defer={false}>
                <script async src="https://www.google.com/recaptcha/api.js?render=6LfBup8eAAAAAFf4MYxd86xLlOMdbDL3h2kGZXQW"></script>
            </Helmet>
            {showAlert && error ? <Alert success={false} message={errorMessage} onDismiss={() => setShowAlert(false)} /> : ''}
            {showAlert && success ? <Alert success={true} message='Message sent!' onDismiss={() => setShowAlert(false)} /> : ''}
            <div className="rounded overflow-hidden shadow-lg py-6 px-6">
                <h1 className="text-5xl font-bold mb-4">Contact Integrative Wellness</h1>
                <p>
                    <a href="https://www.google.com/maps/dir//5000+S+Minnesota+Ave+Unit+200,+Sioux+Falls,+SD+57108">
                        5000 S Minnesota Ave, Suite 200,
                        <br />
                        Sioux Falls, SD 57108
                    </a>
                </p>
                <p className="mt-2">
                    <a href="tel:1-605-271-1348">Phone: 605.271.1348</a>
                    <br />
                    <span>Fax: 605-610-1477</span>
                </p>
                <div className="mt-12">
                    <form onSubmit={e => handleSubmit(e)} action="#" method="POST" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="given-name"
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 border rounded-md"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 border rounded-md"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                Message
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={4}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 border rounded-md"
                                    defaultValue={''}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <button
                                type="submit"
                                disabled={showAlert}
                                className={`w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white focus:outline-none focus:ring-2 bg-green-900  focus:ring-offset-2 focus:ring-green-00 ${showAlert ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer hover:bg-green-700'}`}
                                onClick={e => handleSubmit(e)}
                            >
                                Let's talk
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export interface ContactRequest {
    from: Contact
    message: string
}

export interface Contact {
    name: string
    email: string
}